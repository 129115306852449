import React from 'react';
import {Helmet} from 'react-helmet';

type Props = {
  title: string;
  description: string;
  image: string | null;
  isArticle: boolean;
  url: string;
};

const SEO = ({title, description, image, isArticle, url}: Props) => {
  return (
    <Helmet title={title}>
      <meta charSet="utf-8" />

      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:site_name" content="noy72.com" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={isArticle ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default SEO;
