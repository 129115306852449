import React from 'react';
import {graphql, Link} from 'gatsby';
import {Grid} from 'semantic-ui-react';
import Layout from './layout';
import TagsRow from '../components/TagsRow';
import SEO from '../components/SEO';
import {ArticleQuery} from '../types/graphql-types';

type Props = {
  location: {
    pathname: string;
  };
  data: ArticleQuery;
};

type Node = {
  fields?: {slug?: string | null} | null;
  frontmatter?: {title?: string | null} | null;
};

const Article = ({
  location,
  data: {
    site,
    markdownRemark,
    allMarkdownRemark: {nodes},
  },
}: Props) => {
  const frontmatter = markdownRemark?.frontmatter;
  const fields = markdownRemark?.fields;
  return (
    <Layout>
      <SEO
        title={`${frontmatter?.title} - ${site?.siteMetadata?.title}` || 'Empty'}
        description={fields?.description || 'Empty'}
        image={
          fields?.thumbnail
            ? fields.thumbnail.replace('m.webp', 'h.webp')
            : 'https://i.imgur.com/qvctfPP.png'
        }
        isArticle={true}
        url={`https://noy72.com${location.pathname}`}
      />
      <article>
        <header>
          <div className="meta text--small">{frontmatter?.date}</div>
          <h1
            style={{
              marginTop: '0.5rem',
              marginBottom: '0',
            }}
          >
            {frontmatter?.title}
          </h1>
          <TagsRow tags={frontmatter?.tags} />
        </header>
        <div
          style={{
            marginTop: '2rem',
          }}
          dangerouslySetInnerHTML={{__html: markdownRemark?.html || ''}}
        />
      </article>
      <ArticleFooter locationPath={location.pathname} nodes={nodes} />
    </Layout>
  );
};

const ArticleFooter = ({
  locationPath,
  nodes,
}: {
  locationPath: string;
  nodes: Node[];
}) => {
  const path = locationPath.slice(1); // remove '/'
  const [prevNode, nextNode] = findNextAndPrevArticle(path, nodes);
  return (
    <Grid className="article-footer" centered={true} verticalAlign="middle">
      <Grid.Column width={7} textAlign="right">
        {prevNode ? (
          <Link to={'/' + prevNode?.fields?.slug} className="grey-links">
            <Grid verticalAlign="middle">
              <Grid.Column width={2}>
                <span>前</span>
              </Grid.Column>
              <Grid.Column width={14}>
                <span>{prevNode?.frontmatter?.title}</span>
              </Grid.Column>
            </Grid>
          </Link>
        ) : null}
      </Grid.Column>
      <Grid.Column className="divider" width={2} textAlign="center">
        <Link className="grey-links text--large" to="/">
          Top
        </Link>
      </Grid.Column>
      <Grid.Column width={7} textAlign="left">
        {nextNode ? (
          <Link to={'/' + nextNode?.fields?.slug} className="grey-links">
            <Grid verticalAlign="middle">
              <Grid.Column width={14}>
                <span>{nextNode?.frontmatter?.title}</span>
              </Grid.Column>
              <Grid.Column width={2}>
                <span>次</span>
              </Grid.Column>
            </Grid>
          </Link>
        ) : null}
      </Grid.Column>
    </Grid>
  );
};

/**
 * Find next and previous articles
 * @param path an article path
 * @param nodes all markdown nodes
 * @returns previous and next nodes
 */
const findNextAndPrevArticle = (path: string, nodes: Node[]) => {
  const index = nodes.findIndex(node => node?.fields?.slug === path);
  return [nodes[index + 1], nodes[index - 1]];
};

export const pageQuery = graphql`
  query Article($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      fields {
        description
        thumbnail
      }
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        tags
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`;

export default Article;
