import React from 'react';
import {Link} from 'gatsby';

export default function TagsRow({
  tags,
}: {
  tags: (string | null)[] | undefined | null;
}) {
  return (
    <div className="ui horizontal list content">
      {tags &&
        tags.map(
          tag =>
            tag && (
              <Link
                key={tag}
                to={`/tags/${tag}`}
                className="item text--ssmall grey-links link"
              >{`#${tag}`}</Link>
            )
        )}
    </div>
  );
}
